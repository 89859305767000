<template>
  <div>
    <!-- Devices -->
    <div class="mb-1 d-block font-weight-bolder text-nowrap">
      Venues
    </div>
    <b-card>
      <b-skeleton-table
        v-if="loadingVenues"
        :rows="1"
        :columns="2"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <div v-else>
        <div
          v-for="venue in allVenues"
          :key="venue.id"
          class="profile p-1"
        >
          <div class="row">
            <div
              class="leads collapse-title col-10"
            >
              <span class="d-block font-weight-bolder text-nowrap id ">
                {{ venue.venue_name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="allVenues.length === 0 && loadingVenues === false"
        class="id d-flex justify-content-center align-items-center"
      >
        No Venues Found
        <b-button
          variant="primary"
          class="float-right ml-auto"
          @click="viewAllVenues"
        >
          Add Venues
        </b-button>
      </div>
      <div
        v-else
        class="buttonlink"
      >
        <b-button
          variant="link"
          class="p-0"
          @click="viewAllVenues"
        >
          View All
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import {
  BCard,
  BButton,
  BSkeletonTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showToast, showErrorMessage } from '@/common/global/functions'

Vue.use(Vuesax)

export default {
  components: {
    BCard,
    BButton,
    BSkeletonTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      allVenues: [],
      loadingVenues: false,
    }
  },
  computed: {
    venue() {
      return this.$store.getters['venues/getVenue']
    },
  },
  async mounted() {
    await this.getVenues()
  },
  methods: {
    async getVenues() {
      this.loadingVenues = true
      const payload = {
        offset: 1,
        limit: 2,
        userHasGroupId: this.$route.params.userId,
      }
      try {
        const success = await this.$store.dispatch('venues/getAllVenue', { ...payload })
        if (success) {
          const { data } = this.venue
          this.allVenues = data.results
          showToast('Fetch Venues', 'Venues fetch successfully', 'success')
          this.loadingVenues = false
        }
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('All Venues', message.toString(), 'danger')
          this.spinner = false
        }
      }
    },
    viewAllVenues() {
      this.$router.push(`/users/${this.$route.params.userId}/user-venues-group`)
    },
  },
}
</script>
<style scoped>
.profile{
  border: none;
  margin-bottom: 1rem;
  box-shadow: 0px 3px 11.5px rgba(0,0,0,0.06) !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 7px;
}
.leads{
  width: 40%;
}
.switchs {
  width: 50px;
  padding-inline: 40px;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.id{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
}
.buttonlink{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}
</style>
